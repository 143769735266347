<template>
  <div class="flex-none" :class="slideClass" :style="slideWidth">
    <slot name="slideslot">
    </slot>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  activeBreakpoint: {
    type: Object,
    required: true
  },
});

/**
 * Calculate the stagePadding based on visible items.
 *
 * @returns string
 */
const slideWidth = computed(() => {
  return 'width: calc(100% / ' + props.activeBreakpoint.items + (props.activeBreakpoint.hasOwnProperty('stagePadding') ? props.activeBreakpoint.stagePadding === 0 ? '' : props.activeBreakpoint.stagePadding : '.2') + ');';
});

/**
 * Get classes for the individual slides.
 *
 * @returns string[]
 */
const slideClass = computed((): string[] => {
  let classes: string[] = [];
  // Setup the scroll-snapping elements based on visible items.
  if (props.index % props.activeBreakpoint.items === 0) {
    classes.push('snap-start snap-always');
  }
  classes.push(props.activeBreakpoint.slidePadding === 1 ? 'px-px' : 'px-' + (props.activeBreakpoint.hasOwnProperty('slidePadding') ? props.activeBreakpoint.slidePadding : '5'));
  return classes;
});

</script>
