<template>
  <div v-cloak>
    <div class="relative" :class="{ 'min-h-[55px]': !hasHeader }">
      <slot name="sliderHeader"></slot>
      <div v-if="sliderActive" class="absolute right-0 top-0 hidden lg:flex" :class="arrowContainerClass">
        <button :disabled="!prevActive" :class="arrowClass" @click="gotoPrev" aria-label="Forrige" class="disabled:opacity-50 square-20 bg-contain bg-no-repeat rotate-90 transform focus:outline-none"></button>
        <button :disabled="!nextActive" :class="arrowClass" @click="gotoNext" aria-label="Næste" class="disabled:opacity-50 square-20 bg-contain bg-no-repeat -rotate-90 ml-25 focus:outline-none"></button>
      </div>
    </div>
    <div class="overflow-hidden">
      <div ref="sliderRef" class="overflow-x-scroll scroll-smooth snap-x snap-mandatory snap-slider-scroller">
        <div class="flex min-w-full">
          <SnapSliderSnapSlide v-for="(_, index) in props.items" :key="index" v-slot:slideslot :activeBreakpoint="activeBreakpoint" :index="parseInt(index)" v-cloak>
            <slot :name="index"></slot>
          </SnapSliderSnapSlide>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  lengthOverride: {
    type: Number
  },
  sliderConf: {
    type: Object as PropType<Aller.SliderConf>,
    required: true
  },
  arrowsTop: {
    type: String
  },
  arrowClass: {
    type: String,
    default: 'disabled:bg-svg-arrow-black bg-svg-arrow-black hover:bg-svg-arrow-red-400'
  },
  items: {
    type: Object,
    required: true
  }
});

const { sliderHeader } = useSlots();
const hasHeader = !!sliderHeader;

const emit = defineEmits(['switch']);

const {
  sliderRef,
  currentIndex,
  sliderWidth,
  activeBreakpoint,
  gotoPrev,
  gotoNext,
  slides,
  navigationInActiveItem,
  navigationPadding,
  prevActive,
  nextActive,
  arrowContainerClass,
  sliderLength,
  resize,
  scrollListener,
  observer,
  sliderActive,
} = useAllerSlider(props, hasHeader);


/**
 * Watch index, emit when it is changed.
 */
watch(currentIndex, () => {
  emit('switch', currentIndex.value);
});

/**
 * Vue mounted hook.
 * Setup listeners and resizeObserver
 *
 * @returns void
 */
onMounted((): void => {
  if (sliderRef && sliderRef.value) {
    sliderRef.value.addEventListener('scroll', () => scrollListener(), { passive: true });
    observer.value = new ResizeObserver(() => setTimeout(resize));
    observer.value.observe(sliderRef.value);
  }
});

/**
 * Vue beforeDestroy hook.
 * Disconnect the observer.
 *
 * @returns void
 */
onBeforeUnmount((): void => {
  observer.value?.disconnect();
  sliderRef.value?.removeEventListener('scroll', () => scrollListener());
});
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.snap-slider-scroller::-webkit-scrollbar {
  display: none;
}

@supports (-moz-appearance:none) {
  .snap-slider-scroller {
    scroll-snap-type: x proximity;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.snap-slider-scroller {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>